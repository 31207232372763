import { intializeHeadroom } from "../../../bladejs/initialize-headroom";
import { toggleAdvertiserDisclosure } from "../../../bladejs/toggle-advertiser-disclosure";
import { toggleClasses } from "../../../bladejs/toggle-classes";

const toggleVisible = (element) => {
	element?.classList.toggle("tw-hidden");
};

document.addEventListener("DOMContentLoaded", () => {
	// Used sitewide for hiding/displaying header on scroll
	const headroom = intializeHeadroom();

	// Controls the display of the advertiser disclosure
	toggleAdvertiserDisclosure();

	// Controls the opening and closing of gift guides disclaimer
	document
		.querySelectorAll(".guideDisclaimerToggle")
		?.forEach((guideDisclaimerToggle) => {
			guideDisclaimerToggle.addEventListener("click", () => {
				toggleClasses(document.querySelector(".guideDisclaimerWindow"), [
					"tw-hidden",
					"tw-flex",
				]);
			});
		});

	// Show More/Less Categories in Mobile View
	const showMoreCategoriesBtn = document.getElementById("showMoreCategories");
	showMoreCategoriesBtn?.addEventListener("click", () => {
		const isShowingMore =
			showMoreCategoriesBtn.textContent.trim() === "Show More Categories";
		showMoreCategoriesBtn.textContent = isShowingMore
			? "Show Less Categories"
			: "Show More Categories";

		document
			.querySelectorAll(".featuredCategories .tw-hidden")
			.forEach((el) => {
				toggleClasses(el, ["tw-hidden", "revealed"]);
			});

		if (!isShowingMore) {
			document
				.querySelectorAll(".featuredCategories .revealed")
				.forEach((el) => {
					toggleClasses(el, ["tw-hidden", "revealed"]);
				});
		}
	});

	// Blog post table of contents
	const tocDesktopLinks = document.querySelectorAll("#tocDesktop a");
	const tocMobileLinks = document.querySelectorAll("#tocMobile a");
	const tocMobile = document.getElementById("tocMobile");
	const tocTrigger = document.getElementById("tocTrigger");

	if (document.body.classList.contains("favyBlogPost")) {
		const tocHeaders = document.querySelectorAll(".tocHeader");
		let tocHeaderPositions = [];

		const calculateHeaderPositions = () =>
			Array.from(tocHeaders).map((header) =>
				Math.floor(header.getBoundingClientRect().top + window.scrollY),
			);

		window.addEventListener("load", () => {
			tocHeaderPositions = calculateHeaderPositions();
		});

		window.addEventListener("resize", () => {
			tocHeaderPositions = calculateHeaderPositions();
		});

		window.addEventListener("scroll", () => {
			// Close the mobile TOC when the user scrolls if open
			tocMobile.classList.add("tw-hidden");
			document
				.querySelector("#tocTrigger img")
				.classList.remove("tw-rotate-180");

			// Remove active class from all links
			tocDesktopLinks.forEach((link) => link.classList.remove("tocActive"));
			tocMobileLinks.forEach((link) => link.classList.remove("tocActive"));

			// Add active class to the link that corresponds to the header in view
			const windowPos = window.scrollY;
			for (let i = tocHeaderPositions.length - 1; i >= 0; i--) {
				if (windowPos >= tocHeaderPositions[i]) {
					tocDesktopLinks[i].classList.add("tocActive");
					tocMobileLinks[i].classList.add("tocActive");
					break;
				}
			}
		});
	}

	// Show/Hide Mobile TOC
	tocTrigger?.addEventListener("click", () => {
		document.getElementById("header").classList.remove("headroom--unpinned");
		document.getElementById("header").classList.add("headroom--pinned");
		toggleVisible(tocMobile);
		tocTrigger.querySelector("img").classList.toggle("tw-rotate-180");
	});

	// Hide Mobile TOC on clicking the links
	tocMobileLinks?.forEach((link) => {
		link.addEventListener("click", () => {
			toggleVisible(tocMobile);
		});
	});

	// Gift Guide Selectors
	const productCategory = document.querySelectorAll(".sidebarCategory");
	const entriesContainer = document.querySelectorAll(".giftGuideCategory");
	const categoryEntries = Array.from(entriesContainer).flatMap((container) =>
		Array.from(container.children),
	);
	const twSmallScreen = 640;

	if (window.innerWidth < twSmallScreen) {
		document
			.querySelectorAll(".sidebarCategory, .giftGuideCategory")
			.forEach((el) => {
				el.classList.toggle("tw-flex");
				el.classList.toggle("tw-hidden");
			});
		document
			.querySelectorAll(".expandToggle")
			.forEach((el) => (el.textContent = "+"));
	} else {
		productCategory?.[0]
			?.querySelector("img")
			.classList.add("favy-nav-guide-icon-rotate-open");
		entriesContainer?.[0]?.classList.remove("tw-hidden");
		entriesContainer?.[0]?.classList.add("favy-gift-guide-category-open");
		categoryEntries?.forEach((entry) => {
			entry.classList.remove("tw-hidden");
			entry.classList.add("favy-gift-guide-category-entry-show");
		});
	}

	const closeAll = () => {
		document
			.querySelectorAll(".favy-nav-guide-icon-rotate-open")
			.forEach((el) => el.classList.remove("favy-nav-guide-icon-rotate-open"));
		document
			.querySelectorAll(".favy-gift-guide-category-entry-show")
			.forEach((el) => {
				el.classList.remove("favy-gift-guide-category-entry-show");
				el.classList.add("tw-hidden");
			});
		document
			.querySelectorAll(".giftGuideCategory.favy-gift-guide-category-open")
			.forEach((el) => el.classList.remove("favy-gift-guide-category-open"));
	};

	productCategory?.forEach((category) => {
		category.addEventListener("click", () => {
			closeAll();
			category
				.querySelector("img")
				.classList.toggle("favy-nav-guide-icon-rotate-open");
			const nextCategory = category.nextElementSibling;
			nextCategory.classList.remove("tw-hidden");
			nextCategory.classList.toggle("favy-gift-guide-category-open");
			Array.from(nextCategory.children).forEach((child) => {
				child.classList.remove("tw-hidden");
				child.classList.add("favy-gift-guide-category-entry-show");
			});
		});
	});

	document.querySelectorAll(".guideNavigation")?.forEach((nav) => {
		nav.addEventListener("click", () => {
			closeAll();
			document
				.querySelectorAll(".sidebarCategory, .giftGuideCategory")
				.forEach((el) => {
					el.classList.toggle("tw-flex");
					el.classList.toggle("tw-hidden");
				});
			document.querySelectorAll(".expandToggle").forEach((el) => {
				el.textContent = document
					.querySelector(".sidebarCategory")
					.classList.contains("tw-flex")
					? "-"
					: "+";
			});
		});
	});

	// Menu toggles
	const desktopMenu = document.getElementById("desktopMenu");
	const mobileMenu = document.getElementById("mobileMenu");
	const expandDesktopMenu = document.getElementById("expandDesktopMenu");
	const expandMobileMenu = document.getElementById("expandMobileMenu");

	const toggleMobileMenu = () => {
		headroom.freeze();
		const hamburgerIcon = expandMobileMenu.querySelector("figure");
		const closeIcon = expandMobileMenu.querySelector("p");

		toggleVisible(hamburgerIcon);
		toggleVisible(closeIcon);
		toggleVisible(mobileMenu);
		toggleVisible(tocTrigger);

		document.documentElement.classList.toggle(
			"tw-overflow-hidden",
			!mobileMenu?.classList.contains("tw-hidden"),
		);

		headroom.unfreeze();
	};

	window.addEventListener("resize", () => {
		if (!mobileMenu?.classList.contains("tw-hidden")) toggleMobileMenu();
	});

	expandDesktopMenu?.addEventListener("click", () => {
		toggleVisible(desktopMenu);
	});

	expandMobileMenu?.addEventListener("click", () => {
		toggleMobileMenu();
		tocMobile?.classList.add("tw-hidden");
		tocTrigger?.querySelector("img").classList.remove("tw-rotate-180");
	});
});
